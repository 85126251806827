@import url("https://fonts.googleapis.com/css2?family=Charm:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Charmonman&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dr+Sugiyama&display=swap");

#logo2 {
	top: 100px;
	width: 15%;
	height: auto;
	position: fixed;
	z-index: 1;
	right: 50px;
}

.p-menubar {
	position: fixed;
	z-index: 1;
	width: 100%;
	background-color: transparent !important;
	align-items: center !important;
	justify-content: center !important;
	border: none !important;
}

.p-menuitem {
	background-color: #595959d8;
	padding: 10px;
}
.p-menubar-button {
	background-color: #595959d8;
	padding: 10px;
}

.p-divider-solid.p-divider-horizontal:before {
	border-top-style: none !important;
}

.p-divider .p-divider-content {
	background-color: inherit !important;
}
.speeddial-circle-demo {
	bottom: 50px;
	right: 50px;
	position: fixed;
	z-index: 1;
	width: 100%;
	align-items: center;
	justify-content: center;
	border: none;
}

.p-speeddial-semi-circle.p-speeddial-direction-up {
	left: calc(50% - 2rem);
	bottom: 0;
}

strong {
	color: #ffd54f;
}
a {
	color: #ffd54f;
}

.speeddial-linear-demo .p-speeddial-direction-up {
	left: calc(50% - 2rem);
	bottom: 0;
}
.speeddial-linear-demo .p-speeddial-direction-down {
	left: calc(50% - 2rem);
	top: 0;
}
.speeddial-linear-demo .p-speeddial-direction-left {
	right: 0;
	top: calc(50% - 2rem);
}
.speeddial-linear-demo .p-speeddial-direction-right {
	left: 0;
	top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-circle {
	top: calc(50% - 2rem);
	left: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-up {
	left: calc(50% - 2rem);
	bottom: 0;
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-down {
	left: calc(50% - 2rem);
	top: 0;
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-left {
	right: 0;
	top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-right {
	left: 0;
	top: calc(50% - 2rem);
}
.speeddial-circle-demo
	.p-speeddial-quarter-circle.p-speeddial-direction-up-left {
	right: 0;
	bottom: 0;
}
.speeddial-circle-demo
	.p-speeddial-quarter-circle.p-speeddial-direction-up-right {
	left: 0;
	bottom: 0;
}
.speeddial-circle-demo
	.p-speeddial-quarter-circle.p-speeddial-direction-down-left {
	right: 0;
	top: 0;
}
.speeddial-circle-demo
	.p-speeddial-quarter-circle.p-speeddial-direction-down-right {
	left: 0;
	top: 0;
}
.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-left {
	left: 0;
	bottom: 0;
}
.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-right {
	right: 0;
	bottom: 0;
}
.speeddial-delay-demo .p-speeddial-direction-up {
	left: calc(50% - 2rem);
	bottom: 0;
}
.speeddial-mask-demo .p-speeddial-direction-up {
	right: 0;
	bottom: 0;
}

/* .specialFont {
	font-family: "Dr Sugiyama", cursive;
	text-shadow: 2px 2px black; 
}
/* .specialFont {
	font-family: "Charm";
	text-shadow: 2px 2px black;
} */

main {
	background: rgba(0, 0, 0, 0.4);
	color: white;
	position: relative;
}
